import { MessageOnHover, MessageOnHoverMode } from "@kalyzee/kast-react-player-module";
import {
  ActivityOutputDTO,
  SORT_ORDER,
  StorageSpaceOutputDTO,
  Tokens,
  VOD_SORT_METHOD,
  VOD_STATE,
  VOD_TARGET_MODELS,
  VodOutputDTO,
} from "@kalyzee/kast-web-sdk/http";
import { Button, useStateWithRef, useTriggerable, Video } from "@kalyzee/kast-web-sdk/web-components";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import PageContainer from "../../common/components/page/PageContainer";
import TableVods, { TableVodsRef } from "../../common/components/table/vod/TableVods";
import { TableVodData } from "../../common/components/table/vod/TableVods.constant";
import Pagination from "../../common/components/utils/Pagination";
import { PopupButtonType, PopupIconType, PopupId } from "../../common/components/utils/Popup";
import { showPopup } from "../../common/components/utils/PopupContainer";
import ProgressBar from "../../common/components/utils/ProgressBar";
import { TableSortDirection } from "../../common/components/utils/Table";
import Colors from "../../common/constants/colors";
import { Strings } from "../../common/constants/translation";
import { sdkClient } from "../../common/helpers/sdk";
import { getToken, TokenType } from "../../common/helpers/storage";
import { toastError, toastSuccess } from "../../common/helpers/toast";
import { formatBytes, formatTime } from "../../common/helpers/utils";
import { useAppTranslation } from "../../common/hooks/translation";
import { selectOrganization } from "../../features/session/selectors";
import styles from "./vods.module.css";

const LIMIT = 20;

const VodsPage = () => {
  const { t } = useAppTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const tableDataRef = useRef<TableVodData[]>([]);
  const [itemsChecked, setItemChecked] = useState<TableVodData[]>([]);
  const tableRef = useRef<TableVodsRef>();
  const organization = useAppSelector(selectOrganization);
  const [storageSpaces, setStorageSpaces] = useState<StorageSpaceOutputDTO[]>([]);
  const [currentStorageSpace, setCurrentStorageSpace, currentStorageSpaceRef] = useStateWithRef<StorageSpaceOutputDTO | null>();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [vods, setVods] = useState<VodOutputDTO[]>([]);
  const [vodLoading, setVodLoading] = useState(false);
  const [sorting, setSorting, sortingRef] = useStateWithRef<{ method: VOD_SORT_METHOD; direction: TableSortDirection }>({
    method: VOD_SORT_METHOD.START,
    direction: TableSortDirection.DESC,
  });
  const titleRef = useRef<HTMLDivElement>(null);
  const [displayId, setDisplayId] = useState(false);

  useTriggerable(titleRef, { clickCount: 2 }, () => {
    setDisplayId((c) => !c);
  });

  const loadStorageSpaces = async () => {
    const storageSpaces = (await sdkClient.getHttpClient().storageSpace.getAll()).data;
    let currStorageSpace = undefined;
    if (storageSpaces.length) {
      currStorageSpace = storageSpaces[0];
    }
    setStorageSpaces(storageSpaces);
    if (!currentStorageSpaceRef.current) setCurrentStorageSpace(currStorageSpace);
  };

  const loadVods = async () => {
    const storageSpaceId = currentStorageSpace?._id;
    const skip = page * LIMIT;
    const limit = LIMIT;
    setVodLoading(true);
    try {
      const result = await sdkClient.getHttpClient().vod.getAll({
        filters: storageSpaceId ? `_storageSpace[eq]${storageSpaceId}` : "",
        limit,
        skip,
        uploaded: true,
        uploading: true,
        count: true,
        sortMethod: sorting.method,
        sortOrder: sorting.direction === TableSortDirection.ASC ? SORT_ORDER.ASCENDING : SORT_ORDER.DESCENDING,
      });
      const vods = result.data;
      let count = Number(result.headers["count"]);
      if (isNaN(count)) count = 0;
      setVods(vods);
      const pageCount = Math.max(1, Math.ceil(count / LIMIT));
      setPageCount(pageCount);
      setPage((c) => Math.min(c, pageCount - 1));
    } finally {
      setVodLoading(false);
    }
  };

  const showPopupDeleteVods = (vodIds: string[], successCallback?: () => void) => {
    const translationKeys = {
      one: {
        title: Strings.VOD_POPUP_DELETE_CONFIRMATION_TITLE,
        content: Strings.VOD_POPUP_DELETE_CONFIRMATION_CONTENT,
        cancel: Strings.VOD_POPUP_DELETE_CONFIRMATION_CANCEL,
        validate: Strings.VOD_POPUP_DELETE_CONFIRMATION_VALIDATE,
      },
      many: {
        title: Strings.VOD_POPUP_DELETE_MANY_CONFIRMATION_TITLE,
        content: Strings.VOD_POPUP_DELETE_MANY_CONFIRMATION_CONTENT,
        cancel: Strings.VOD_POPUP_DELETE_MANY_CONFIRMATION_CANCEL,
        validate: Strings.VOD_POPUP_DELETE_MANY_CONFIRMATION_VALIDATE,
      },
    };
    const displayed = showPopup({
      id: PopupId.SYSTEM_WARNING_FACTORY_RESET,
      title: t(translationKeys[vodIds.length > 1 ? "many" : "one"].title),
      buttons: [
        {
          element: t(translationKeys[vodIds.length > 1 ? "many" : "one"].cancel),
          type: PopupButtonType.CANCEL,
        },
        {
          element: t(translationKeys[vodIds.length > 1 ? "many" : "one"].validate),
          type: PopupButtonType.VALIDATE,
          onClick: () => {
            successCallback?.();
            return true;
          },
        },
      ],
      content: t(translationKeys[vodIds.length > 1 ? "many" : "one"].content, { count: vodIds.length }),
      iconContent: PopupIconType.WARNING,
      enableCloseButton: false,
      enableNotShowAgain: false,
    });
    // not show again
    if (!displayed) {
      successCallback?.();
    }
  };

  const deleteVods = (vodIds: string[]) => {
    showPopupDeleteVods(vodIds, async () => {
      setVodLoading(true);
      try {
        await sdkClient.getHttpClient().vod.deleteMany(vodIds);
        await loadStorageSpaces();
        await loadVods();
        toastSuccess(t(vodIds.length > 1 ? Strings.VOD_DELETE_MANY_SUCCESS : Strings.VOD_DELETE_SUCCESS));
      } catch (error) {
        console.error("Error : ", error);
        toastError(t(Strings.AN_ERROR_OCCURED));
      } finally {
        setVodLoading(false);
      }
    });
  };

  const recoverVod = async (vodId: string) => {
    try {
      const result = await sdkClient.getHttpClient().vod.recover(vodId);
      if (result.data?.success) {
        await loadStorageSpaces();
        await loadVods();
      } else {
        console.error("Error : impossible to recover");
        toastError(t(Strings.AN_ERROR_OCCURED));
      }
    } catch (error) {
      console.error("Error : ", error);
      toastError(t(Strings.AN_ERROR_OCCURED));
    } finally {
      setVodLoading(false);
    }
  };

  const viewVod = (vod: VodOutputDTO) => {
    const contentLocation = vod.contentLocations?.length ? vod.contentLocations[0] : undefined;
    const src = contentLocation?.videoUrl;
    if (!src) {
      toastError(t(Strings.ERROR_CONTACT_ADMINISTRATOR));
      return;
    }
    showPopup({
      id: PopupId.VOD_PREVIEW,
      buttons: [
        {
          element: t(Strings.OK),
          type: PopupButtonType.OK,
        },
      ],
      content: (
        <Video
          callbacks={{
            error(m, e) {
              console.log("ERROR load video : ", m, e);
              if ((e as any)?.details === "manifestLoadError") {
                toastError(t(Strings.ERROR_CONTACT_ADMINISTRATOR));
              }
            },
          }}
          autoPlay
          controls
          src={{ type: "hls", src }}
          style={{ width: "100%", height: "100%" }}
        />
      ),
      enableNotShowAgain: false,
    });
  };

  const init = async () => {
    /*
     TODO !!!!! 
     Implement the sdk throughout the project for the httpClient part!
    */
    const tokens: Tokens = {
      token: getToken(TokenType.Normal) ?? undefined,
      refreshToken: getToken(TokenType.Refresh) ?? undefined,
    };
    if (tokens.token) await sdkClient.authenticateHttpClient({ method: { type: "tokens", data: tokens } });
    await loadStorageSpaces();
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (currentStorageSpace === undefined) return;
    loadVods();
  }, [currentStorageSpace, page, sorting]);

  const generateTableData = (): TableVodData[] => {
    if (loading) return tableDataRef.current;
    const result: TableVodData[] = [];
    vods.forEach((vod) => {
      const vodId = vod._id;

      const currentData: TableVodData | undefined = tableDataRef.current.find((d) => d.vodId === vodId);
      let targetName = "";
      let targetStartedAt: Date | undefined = undefined;
      let targetEndedAt: Date | undefined = undefined;
      let targetLink: string | undefined = undefined;
      if (vod.target) {
        if (vod.targetModel === VOD_TARGET_MODELS.ACTIVITY) {
          const activity: ActivityOutputDTO = vod.target;
          targetName = activity.name;
          if (activity.startDatetime) targetStartedAt = new Date(activity.startDatetime);
          if (activity.endDatetime) targetEndedAt = new Date(activity.endDatetime);
          const shortId = activity.shortId;
          if (shortId !== undefined && activity._organization === organization?._id) {
            const kaptureUrl = (organization as any).kaptureUrl;
            if (kaptureUrl) {
              try {
                const url = new URL(kaptureUrl).href;
                targetLink = `${url}${url.endsWith("/") ? "" : "/"}kapt/${shortId}`;
              } catch (err) {}
            }
          }
        }
      }
      const newData: TableVodData = {
        vod,
        vodId,

        checked: currentData?.checked ?? false,

        date: vod.recordStartedAt ? new Date(vod.recordStartedAt) : undefined,
        targetName,
        targetStartedAt,
        targetEndedAt,
        targetLink,
        state: vod.state,
        error: vod.state === VOD_STATE.PUBLISH_ERROR || vod.state === VOD_STATE.UPLOAD_ERROR || vod.state === VOD_STATE.DELETE_ERROR,
        duration: vod.duration,
        size: vod.size,
      };

      const data: TableVodData = Object.assign(currentData ?? {}, newData);
      result.push(data);
    });
    tableDataRef.current = result;
    return tableDataRef.current;
  };

  const updateItemChecked = () => {
    const current = tableDataRef.current.filter((d) => d.checked);
    let update = false;
    if (current.length === itemsChecked.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const currItem1 of current) {
        if (!itemsChecked.find((currItem2) => currItem1.vodId === currItem2.vodId)) {
          update = true;
          break;
        }
      }
    } else {
      update = true;
    }
    if (update) setItemChecked(current);
  };
  updateItemChecked();

  // ---------------- BUTTONS ------------ //

  const renderDeleteButton = () => {
    const buttonSupportRef = { current: null };
    const newItemChecked = tableDataRef.current.filter((v) => v.checked);
    const buttonEnabled = newItemChecked.length;
    return (
      <div>
        {!buttonEnabled ? (
          <MessageOnHover targetRef={buttonSupportRef} mode={MessageOnHoverMode.INFO} message={t(Strings.VOD_BUTTON_DELETE_HOVER_MESSAGE)} />
        ) : null}

        <div ref={buttonSupportRef}>
          <Button
            title={t(Strings.VOD_BUTTON_DELETE, { details: buttonEnabled ? ` (${newItemChecked.length})` : "" })}
            onPress={() => {
              deleteVods(newItemChecked.map((i) => i.vodId));
            }}
            disabled={!buttonEnabled}
          />
        </div>
      </div>
    );
  };

  const onItemCheck = (item: TableVodData) => {
    updateItemChecked();
  };

  const renderTable = () => {
    return (
      <TableVods
        className={styles.table}
        ref={tableRef}
        displayId={displayId}
        onItemChecked={onItemCheck}
        style={vodLoading ? { opacity: 0.5, pointerEvents: "none" } : {}}
        data={generateTableData()}
        onSort={(key, dir) => {
          let method = VOD_SORT_METHOD.START;
          if (key === "size") method = VOD_SORT_METHOD.SIZE;
          if (key === "duration") method = VOD_SORT_METHOD.DURATION;
          if (sortingRef.current.method === method && sortingRef.current.direction === dir) return false;
          setSorting({ method, direction: dir });
          setPage(0);
          return false;
        }}
        onAction={(action, item) => {
          if (action === "view") {
            viewVod(item.vod);
          } else if (action === "delete") {
            deleteVods([item.vodId]);
          } else if (action === "recover") {
            recoverVod(item.vodId);
          }
        }}
      />
    );
  };

  const renderStorageSpace = (storageSpace: StorageSpaceOutputDTO) => {
    const style: React.CSSProperties = {};
    const isCurrent = storageSpace._id === currentStorageSpace?._id;
    if (isCurrent) {
      Object.assign(style, { backgroundColor: Colors.getBlueBayoux(), cursor: "auto" });
    }
    const isFull = (storageSpace.used ?? 0) >= storageSpace.total;
    if (isFull) {
      Object.assign(style, { backgroundColor: Colors.getTorchRed(isCurrent ? 0.8 : 0.5) });
    }
    const count = storageSpace.videos?.count ?? 0;
    const percentage = (storageSpace.usedInPercentage ?? 0) * 100;
    return (
      <div
        key={`storage_spaces_${storageSpace._id}`}
        className={styles.storageSpaceContainer}
        style={style}
        onClick={() => {
          if (currentStorageSpace?._id !== storageSpace.id) setCurrentStorageSpace(storageSpace);
        }}
      >
        <div className={styles.storageSpaceName}>{storageSpace.name ?? t(Strings.VODS_STORAGE_SPACE_NAME)}</div>
        <div className={styles.storageSpaceValues}>
          {t(Strings.VODS_STORAGE_SPACE_USED, {
            value1: formatBytes(storageSpace.used ?? 0, 1),
            value2: formatBytes(storageSpace.total ?? 0, 1),
          })}
        </div>
        <div className={styles.storageSpaceProgressBar}>
          <ProgressBar
            showPercentage={false}
            className={styles.progressBar}
            style={{ "--inner-color": isFull ? Colors.getTorchRed() : Colors.getMountainMeadow() } as any}
            percentage={percentage}
          />
          <div className={styles.progressBarValue}>{`${percentage.toFixed(0)}%`}</div>
        </div>
        {count > 0 ? (
          <div className={styles.storageSpaceVideos}>
            {t(Strings.VODS_STORAGE_SPACE_VIDEO_DETAILS, {
              count: count,
              time: formatTime(storageSpace.videos?.totalDuration ?? 0),
            })}
          </div>
        ) : null}
        {isFull ? (
          <>
            <div className={styles.storageSpaceLimitReached}>
              {`${t(Strings.VODS_STORAGE_SPACE_LIMITE_REACHED)}`}
              <span style={{ marginLeft: "10px" }}>⚠️</span>
            </div>
            <div className={styles.storageSpaceLimitReachedMessage}>{t(Strings.VODS_STORAGE_SPACE_LIMITE_REACHED_MESSAGE)}</div>
          </>
        ) : null}
      </div>
    );
  };

  const renderStorageSpaces = () => {
    return <div className={styles.storageSpacesContainer}>{storageSpaces.map((s) => renderStorageSpace(s))}</div>;
  };

  const renderVods = () => <div className={styles.emptyDataMessage}>{t(Strings.VODS_NO_VOD)}</div>;

  const renderButtons = () => <div className={styles.buttonContainer}>{renderDeleteButton()}</div>;

  const notEmptyData = vods.length > 0;
  return (
    <PageContainer titleRef={titleRef} title={t(Strings.VODS_PAGE_TITLE)} subtitle={t(Strings.VODS_PAGE_SUBTITLE)}  loading={loading}>
      {renderStorageSpaces()}
      <div className={styles.container}>{notEmptyData ? renderTable() : renderVods()}</div>
      {pageCount > 1 ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            selected={page}
            textColor={Colors.getMainWhite(0.5)}
            textColorSelected={Colors.getMainWhite()}
            count={pageCount}
            onSelected={(s) => setPage(s)}
            style={{ marginTop: 10 }}
          ></Pagination>
        </div>
      ) : null}
      {notEmptyData ? renderButtons() : null}
    </PageContainer>
  );
};

export default VodsPage;
