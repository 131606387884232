import React, { ForwardedRef, useImperativeHandle } from 'react';
import { addPopupToNotShowAgain, getPopupToNotShowAgain } from '../../helpers/storage';
import { useRender } from '../../hooks/component';
import Popup, { PopupId, PopupOnCloseData, PopupProps, PopupRef } from './Popup';

import styles from './PopupContainer.module.css';

let queue: JSX.Element[] = [];
let containerRef: PopupContainerRef | undefined;

export const showPopup = (
  props: PopupProps,
  ref? : React.MutableRefObject<PopupRef | null>,
) : boolean => {
  const popupIp : PopupId = props.id;
  const popupsToNotShowAgain : PopupId[] = getPopupToNotShowAgain();
  if (popupsToNotShowAgain.includes(popupIp)) {
    return false;
  }
  const element = (
    <Popup
      {...props}
      onClose={(data : PopupOnCloseData) => {
        props?.onClose?.(data);
        queue = queue.filter((el) => el !== element);
        containerRef?.render();
        if (data.notShowAgain && !data.closeFromClickOutside) {
          addPopupToNotShowAgain(data.popupId);
        }
      }}
      ref={ref}
    />
  );
  queue.push(element);
  containerRef?.render();
  return true;
};

export interface PopupContainerProps {
  className?: string;
  style?: React.CSSProperties;
}

export interface PopupContainerRef {
  render: () => void;
}

const PopupContainer = React.forwardRef(({
  className,
  style,
}: PopupContainerProps, forwardRef: ForwardedRef<PopupContainerRef | undefined>) => {
  const render = useRender();
  const forwardedRef: PopupContainerRef = {
    render,
  };
  useImperativeHandle(forwardRef, () => (forwardedRef));
  containerRef = forwardedRef;

  const renderPopup = () => queue[0];

  const divStyle : React.CSSProperties = {};
  if (style) Object.assign(divStyle, style);
  if (!queue.length) divStyle.display = 'none';
  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={divStyle}
    >
      <div className={styles.content}>
        {renderPopup()}
      </div>
    </div>
  );
});

export default PopupContainer;
