import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { install } from 'resize-observer';
import './index.css';
import { Provider } from 'react-redux';
import App from './app/App';
import { registerRootSaga, setupStore } from './app/store';
import rootSaga from './app/rootSaga';
import reportWebVitals from './reportWebVitals';
import './common/helpers/i18n';

install();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const store = setupStore();
registerRootSaga(rootSaga);

// FOR DEBUG
// EN -> https://en.reactjs.org/docs/strict-mode.html
// FR -> https://fr.reactjs.org/docs/strict-mode.html
const ENABLE_STRICT_MODE = false;
const renderApp = () => (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

root.render(
  <Suspense fallback>
    {
      ENABLE_STRICT_MODE ? (
        <React.StrictMode>
          { renderApp() }
        </React.StrictMode>
      ) : (
        renderApp()
      )
    }
  </Suspense>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
