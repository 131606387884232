import React, { useEffect, useImperativeHandle, useState } from 'react';

import styles from './CustomCheckBox.module.css';

export enum CheckMarkType {
  Normal,
  Bar,
}

const DEFAULT_IS_SELECTED = false;
const DEFAULT_MARK_TYPE = CheckMarkType.Normal;

export interface CustomCheckBoxProps {
  isSelected?: boolean,
  setSelected?: (selected: boolean) => void,
  markType?: CheckMarkType,
  disabled?: boolean,
  className?: string;
  style?: React.CSSProperties;
}

export interface CustomCheckBoxRef {
  isChecked: () => boolean,
  check: (m? : CheckMarkType) => void,
  uncheck: () => void,
  getMarkType: () => CheckMarkType,
  setMarkType: (m : CheckMarkType) => void,
}

export const CustomCheckBox = React.forwardRef((
  {
    isSelected = DEFAULT_IS_SELECTED,
    setSelected,
    markType = DEFAULT_MARK_TYPE,
    disabled = false,
    className,
    style,
  }: CustomCheckBoxProps,
  forwardRef: React.ForwardedRef<CustomCheckBoxRef | undefined>,
) => {
  const [checked, setChecked] = useState<boolean>(isSelected ?? DEFAULT_IS_SELECTED);
  const [mark, setMark] = useState<CheckMarkType>(markType ?? DEFAULT_MARK_TYPE);

  let markStyle;
  if (mark === CheckMarkType.Bar) markStyle = styles.customCheckMarkBar;
  else markStyle = styles.customCheckMarkNormal;

  useEffect(() => {
    setChecked(isSelected ?? DEFAULT_IS_SELECTED);
  }, [isSelected]);

  useEffect(() => {
    setMark(markType ?? DEFAULT_MARK_TYPE);
  }, [markType]);

  useImperativeHandle(forwardRef, () => ({
    isChecked: () => checked,
    check: (m? : CheckMarkType) => {
      if (m !== undefined) setMark(m);
      setChecked(true);
    },
    uncheck: () => setChecked(false),
    getMarkType: () => mark,
    setMarkType: (m : CheckMarkType) => setMark(m),
  }), [checked, mark]);

  const classes = [styles.customCheckboxContainer];
  if (className) classes.push(className);
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <label
      className={classes.join(' ')}
      style={style}
      onClick={(ev) => {
        if (!disabled) ev.stopPropagation();
      }} // stop progation for the onClick event of parents
    >
      <input
        type="checkbox"
        onChange={() => {
          const isChecked = !checked;
          if (isChecked) setMark(CheckMarkType.Normal);
          setChecked(isChecked);
          setSelected?.(isChecked);
        }}
        checked={checked}
        disabled={disabled}
      />
      <span className={markStyle} />
    </label>
  );
});

export default {
  CustomCheckBox,
};
