import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getEnvBuildDate } from './env';

// Warning : in Firefox, you need to close the browser and restart it
// in order for a language update to be effective

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { // For i18next-browser-languagedetector to search in navigator first
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
      // caches: [], // Doesn't change firefox behavior
    },
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    },
    backend: {
      loadPath: `locales/{{lng}}/translation.json?date=${getEnvBuildDate()?.toISOString()}` // avoid caching between two version
    }
  });

export default i18n;
