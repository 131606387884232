import React, { useEffect, useImperativeHandle, useState } from 'react';
import Colors from '../../constants/colors';
import { useOuterClickRef } from '../../hooks/click';
import { KeyboardAction, useKeyboardTouched } from '../../hooks/keyboard';

const DEFAULT_CLASSNAME = 'overlay-container';

const containerOverlayStyle : React.CSSProperties = {
  position: 'fixed',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: Colors.getGrayLevel(0.1, 0.6),
};

const OVERLAY_MARGIN = 40; // by default
const containerStyle : React.CSSProperties = {
  position: 'relative',
  width: `calc(100% - ${2 * OVERLAY_MARGIN}px)`,
  height: `calc(100% - ${2 * OVERLAY_MARGIN}px)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export interface OverlayContainerProps {
  children? : React.ReactNode;
  onDisplay?: (display : boolean) => void;
  contentStyle?: React.CSSProperties;
  className?: string;
  style?: React.CSSProperties;
}
export interface OverlayContainerRef {
  show: () => void;
  hide: () => void;
  isDisplaying : () => boolean;
}
const OverlayContainer = React.forwardRef((
  {
    children,
    onDisplay,
    contentStyle,
    className = DEFAULT_CLASSNAME,
    style,
  } : OverlayContainerProps,
  forwardRef: React.ForwardedRef<OverlayContainerRef | undefined>,
) => {
  const [clickOuterEnabled, setClickOuterEnabled] = useState<boolean>(false);
  const [display, setDisplay] = useState<boolean>(false);
  const ref = useOuterClickRef<HTMLDivElement>(() => {
    if (clickOuterEnabled) setDisplay(false);
  });
  useKeyboardTouched((
    action: KeyboardAction,
    key: string,
    repeat: boolean,
    _event: KeyboardEvent,
  ) => {
    if (action === KeyboardAction.DOWN && repeat === false && key === 'Escape') setDisplay(false);
  });

  useEffect(() => {
    let timeout : NodeJS.Timeout;
    onDisplay?.(display);
    if (display) {
      timeout = setTimeout(() => {
        setClickOuterEnabled(true);
      }, 500);
    } else {
      setClickOuterEnabled(false);
    }
    return () => {
      if (timeout) clearInterval(timeout);
    };
  }, [display]);

  useImperativeHandle(forwardRef, () => ({
    show: () => {
      setDisplay(true);
    },
    hide: () => setDisplay(false),
    isDisplaying: () => display,
  }));

  const renderContent = () => {
    if (!display) return null;
    return (
      <div
        className={className ? `${className}-content` : undefined}
        style={{ ...containerStyle, ...(contentStyle || {}) }}
        ref={ref}
      >
        { children }
      </div>
    );
  };

  const divStyle : React.CSSProperties = { ...containerOverlayStyle };
  if (style) Object.assign(divStyle, style);
  if (!display) Object.assign(divStyle, { display: 'none' });
  return (
    <div
      className={className}
      style={divStyle}
    >
      { renderContent() }
    </div>
  );
});

export default OverlayContainer;
