import { createError } from '../../features/error/utils';
import { getEnvApiMode, getEnvApiUrl, getEnvApiUrls } from './env';
import { ErrorType, ResponseError } from './errors';

import storage from './storage';

enum ApiMode {
  PROD = "production",
  TEST = "test",
  DEV = "dev",
  LOCAL = "local",
  IP = "ip",
  ABO = "abo",
  DRO = "dro",
}
const IP = "192.168.1.58";

export let API_MODE = getEnvApiMode() ?? ApiMode.PROD; // use .env => REACT_APP_API_MODE=local


const getUrl = () => new URL(window.location.href);

// Source: react-boilerplate => https://github.com/react-boilerplate/react-boilerplate  (app/utils/request.js)

/**
 * Build backend url
 *
 * @returns {string} Returns a url as string
 */
const buildApiBaseUrl = (protocol : string) => {
  const url = getUrl();
  const hostNameSplitted = url.hostname.split('.');
  hostNameSplitted.splice(0, 1);
  const hostNameStr = hostNameSplitted.join('.');
  return `${protocol}//api.${hostNameStr}`;
};

/**
 * Get the url from env variables if exists
 *
 * @returns {string} Returns a url as string
 */
const getApiUrlFromEnvironmentVariable = (): string | undefined => {
  const url = getUrl();
  const envApiUrls = getEnvApiUrls();
  let result: string | undefined;
  Object.keys(envApiUrls).forEach((k) => {
    const hostname = k;
    if (hostname && url.hostname.match(hostname)?.length) {
      result = envApiUrls[k];
    }
  });
  return result ?? getEnvApiUrl();
}

/**
 * Get the current backend base (root) url
 *
 * @returns {string} Returns a url as string
 */
export const getHttpApiBaseUrl = () => {
  if (API_MODE === ApiMode.ABO) return "https://abo.dev.kalyzee.com/606ee9c4bf11b4001c9ac54b";
  if (API_MODE === ApiMode.DRO) return "https://dro-1.dev.kalyzee.com/606ee9c4bf11b4001c9ac54b";
  if (API_MODE === ApiMode.LOCAL) return "http://localhost:8080/606ee9c4bf11b4001c9ac54b/";
  if (API_MODE === ApiMode.IP) return `http://${IP}:8080/606ee9c4bf11b4001c9ac54b/`;
  if (API_MODE === ApiMode.TEST) return "https://api.kalyzee.kast.app/";
  if (API_MODE === ApiMode.DEV) return "https://api.kalyzee.dev.kast.app/";
  const url = getUrl();
  const envApiUrl = getApiUrlFromEnvironmentVariable();
  if (envApiUrl) return envApiUrl;
  return buildApiBaseUrl(url.protocol);
};

export const getWebsocketApiBaseUrl = () => {
  const url = getUrl();
  const envApiUrl = getApiUrlFromEnvironmentVariable();
  if (envApiUrl) {
    const urlObj = new URL(envApiUrl);
    // Keep same security level : http -> ws & https -> wss
    urlObj.protocol = urlObj.protocol.replace("http", "ws");
    return urlObj.href;
  }
  // Keep same security level : http -> ws & https -> wss
  return `${buildApiBaseUrl(url.protocol.replace("http", "ws"))}`;
};

export const getGenerateWebRTCSessionTokenUrl = (deviceId: string) => `${getHttpApiBaseUrl()}/socket/token/webrtc/${deviceId}`;

export const getUserSocketUrl = () => {
  if (API_MODE === ApiMode.ABO) return "https://abo.dev.kalyzee.com/user?organization=606ee9c4bf11b4001c9ac54b";
  if (API_MODE === ApiMode.DRO) return "https://dro-1.dev.kalyzee.com/?organization=606ee9c4bf11b4001c9ac54b";
  if (API_MODE === ApiMode.LOCAL) return "http://localhost:8080/user?organization=606ee9c4bf11b4001c9ac54b";
  if (API_MODE === ApiMode.IP) return `http://${IP}:8080/?organization=606ee9c4bf11b4001c9ac54b`;
  if (API_MODE === ApiMode.TEST) return "https://api.kalyzee.kast.app/user";
  if (API_MODE === ApiMode.DEV) return "https://api.kalyzee.dev.kast.app/user";
  const url = new URL(getWebsocketApiBaseUrl());
  url.pathname = `${url.pathname.endsWith('/') ? url.pathname : `${url.pathname}/`}user`;
  // Keep same security level : http -> ws & https -> wss
  return url.href;
};

export const getWebRTCSocketUrl = () => {
  if (API_MODE === ApiMode.ABO) return "https://abo.dev.kalyzee.com/webrtc?organization=606ee9c4bf11b4001c9ac54b";
  if (API_MODE === ApiMode.DRO) return "https://dro-1.dev.kalyzee.com/?organization=606ee9c4bf11b4001c9ac54b";
  if (API_MODE === ApiMode.LOCAL) return "http://localhost:8080/webrtc?organization=606ee9c4bf11b4001c9ac54b";
  if (API_MODE === ApiMode.IP) return `http://${IP}:8080/?organization=606ee9c4bf11b4001c9ac54b`;
  if (API_MODE === ApiMode.TEST) return "https://api.kalyzee.kast.app/webrtc";
  if (API_MODE === ApiMode.DEV) return "https://api.kalyzee.dev.kast.app/webrtc";
  const url = new URL(getWebsocketApiBaseUrl());
  url.pathname = `${url.pathname.endsWith('/') ? url.pathname : `${url.pathname}/`}webrtc`;
  // Keep same security level : http -> ws & https -> wss
  return url.href;
};

export const getAuthHeaders = (token: string) => ({ Authorization: `Bearer ${token}` });

// TODO : token retrieval
export const getHeaders = (authenticated: boolean) => {
  let headers = {
    'Content-Type': 'application/json',
  };
  if (authenticated) {
    const token = storage.getToken();
    if (token) {
      const authHeaders = getAuthHeaders(token);
      headers = { ...headers, ...authHeaders };
    }
  }
  return headers;
};

export const getInit = (
  method: string,
  body?: object,
  authenticated: boolean = true,
) => {
  const init = {
    method,
    body: JSON.stringify(body as BodyInit),
    headers: getHeaders(authenticated),
  };
  return init;
};

export const request = async (
  url: string,
  method: string,
  body?: object,
  authenticated: boolean = true,
) : Promise<any> => {
  const fullUrl = `${getHttpApiBaseUrl()}${url}`;
  let response;
  try {
    response = await fetch(fullUrl, getInit(method, body, authenticated));
  } catch (error) {
    // Network error (< 200)
    // TODO : see how to retrieve status
    throw new ResponseError(createError(ErrorType.NetworkError));
  }
  // The Api might return an error that doesn't have a JSON body
  // and is only described by the Http status :
  // if (response.status === 460 || response.status === 403) {
  //   throw new ResponseError({ type: ErrorType.ApiHttpError, code: response.status });
  // }
  // Past this point, the request is supposed to have a JSON body
  let data;
  try {
    data = await response.json();
  } catch (error) {
    // An error occured while parsing the JSON body
    // throw new ResponseError({ type: ErrorType.ParsingError, message: 'Parsing error' });
  }
  if (response.ok) {
    return data;
  }
  // The api error is described by the JSON body :
  throw new ResponseError(createError(ErrorType.ApiError, response.status, data?.message, data));
};

export default {
  request,
  getWebsocketApiBaseUrl,
};
