import React, { useState } from 'react';
import { DeviceVideoRecordState } from '@kalyzee/kast-websocket-module';

import styles from './DeviceCard.module.css';

import CameraOffSvg from '../../assets/devices/camera-off.svg';

import { DeviceData } from './interfaces';
import { CustomCheckBox } from '../../common/components/form/CustomCheckBox';
import { Strings } from '../../common/constants/translation';
import { useAppTranslation } from '../../common/hooks/translation';

interface DeviceCardProps {
  device?: DeviceData,
  isSelectMode?: boolean,
  select?: () => void,
}

enum DeviceStatus {
  Online,
  Offline,
  Publishing,
  Recording,
  PublishingAndRecording,
}

const statusNamesMap: { [status in DeviceStatus]: Strings } = {
  [DeviceStatus.Online]: Strings.ONLINE,
  [DeviceStatus.Offline]: Strings.OFFLINE,
  [DeviceStatus.Publishing]: Strings.LIVE,
  [DeviceStatus.Recording]: Strings.RECORD,
  [DeviceStatus.PublishingAndRecording]: Strings.LIVE_RECORD,
};

const getStatus = (device: DeviceData) => {
  if (device.isOnline) {
    if (device.isInLive) {
      if (device.recordState === DeviceVideoRecordState.RUNNING) {
        return DeviceStatus.PublishingAndRecording;
      }
      return DeviceStatus.Publishing;
    }
    if (device.recordState === DeviceVideoRecordState.RUNNING) {
      return DeviceStatus.Recording;
    }
    return DeviceStatus.Online;
  }
  return DeviceStatus.Offline;
};

export const DeviceCard = ({ device, isSelectMode = false, select }: DeviceCardProps) => {
  const { t } = useAppTranslation();

  if (!device) {
    return (
      <div
        className={styles.searching}
      >
        <div className={styles.deviceImgWrapper}>
          <img src={CameraOffSvg} alt="camera-off" />
        </div>
        <div className={styles.deviceInfo}>
          <div className={styles.deviceStatus}>
            <span className={styles.dot} />
            <span className={styles.dot} />
            <span className={styles.dot} />
          </div>
        </div>
      </div>
    );
  }

  const status = getStatus(device);

  const renderCheckbox = () => (
    <div className={styles.checkBoxWrapper}>
      <CustomCheckBox // Only visual, click already handled by onClick on the card
        setSelected={() => {}}
        isSelected={device.userSelected && !!isSelectMode}
        disabled // this avoid card not being selected when clicking on checkbox
      />
    </div>
  );

  let cardStyle = styles.deviceCard;

  const selectedStyle = (device.userSelected && isSelectMode) ? styles.selected : '';

  let extraStyle;
  if (status === DeviceStatus.Online) extraStyle = styles.online;
  else if (status === DeviceStatus.Offline) extraStyle = styles.offline;
  else extraStyle = styles.active;

  cardStyle = `${cardStyle} ${selectedStyle} ${extraStyle}`;

  return (
    <div
      onClick={() => { if (select) select(); }}
      onKeyPress={() => {}}
      tabIndex={0}
      aria-label="select device"
      role="button"
      className={cardStyle}
    >
      <div className={styles.deviceHeader}>
        { renderCheckbox() }
        <div className={styles.deviceStatus}>
          <span>{t(statusNamesMap[status])}</span>
        </div>
      </div>
      <div className={styles.deviceInfo}>
        <span className={styles.deviceName}>{device.displayName}</span>
      </div>
    </div>
  );
};



export default {
  DeviceCard,
};
