import React, { createRef, useImperativeHandle, useRef } from "react";
import { Strings } from "../../../constants/translation";
import { useRender } from "../../../hooks/component";
import { useAppTranslation } from "../../../hooks/translation";
import { useElementSize } from "../../../hooks/window";
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableSortDirection, TableStyle } from "../../utils/Table";

import { MessageOnHover, MessageOnHoverMode } from "@kalyzee/kast-react-player-module";
import { Touchable, useTriggerable } from "@kalyzee/kast-web-sdk/web-components";
import Colors from "../../../constants/colors";
import { formatBytes, formatTime } from "../../../helpers/utils";
import commonStyles from "../TableCommon.module.css";
import { TableVodData } from "./TableVods.constant";
import styles from "./TableVods.module.css";
import { VOD_STATE } from "@kalyzee/kast-web-sdk/http";

export interface TableVodsRef {
  render: () => void;
}

export interface TableVodsProps {
  displayId?: boolean;
  data: TableVodData[];
  defaultSort?: TableSortDirection;
  onItemChecked?: (item: TableVodData) => void;
  onSort?: (key: string, dir: TableSortDirection) => boolean;
  onAction?: (action: "view" | "delete" | "recover", item: TableVodData) => void;
  className?: string;
  style?: React.CSSProperties;
}
const TableVods = React.forwardRef(
  (
    { displayId = false, data, defaultSort = TableSortDirection.DESC, onItemChecked, onSort, onAction, className, style }: TableVodsProps,
    forwardRef: React.ForwardedRef<TableVodsRef | undefined>
  ) => {
    const { t } = useAppTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const render = useRender();
    const size = useElementSize(containerRef);

    useImperativeHandle(forwardRef, () => ({
      render,
    }));

    const generateConfiguration = () => {
      const columnConfiguration: TableConfColumn<TableVodData>[] = [
        {
          type: TableColumnType.CHECKBOX,
          key: "checked",
        },
        {
          type: TableColumnType.CLASSIC,
          key: "date",
          width: "12rem",
          title: t(Strings.VODS_COLUMN_TITLE_DATE),
          header: { className: commonStyles.tableHeaderCellDevice },
          item: { className: commonStyles.tableCellDevice },
          defaultSort,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "targetName",
          title: t(Strings.VODS_COLUMN_TITLE_TARGET),
          minWidth: "20rem",
          enableSort: false,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "state",
          title: "",
          width: "50px",
          enableSort: false,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "duration",
          title: t(Strings.VODS_COLUMN_TITLE_DURATION),
          width: "120px",
        },
        {
          type: TableColumnType.CLASSIC,
          key: "size",
          title: t(Strings.VODS_COLUMN_TITLE_SIZE),
          width: "120px",
        },
        {
          type: TableColumnType.CLASSIC,
          key: "actions",
          title: t(Strings.VODS_COLUMN_TITLE_ACTIONS),
          width: "20%",
          minWidth: "18rem",
          enableSort: false,
        },
      ];

      if (displayId) {
        columnConfiguration.splice(2, 0, {
          type: TableColumnType.CLASSIC,
          key: "vodId",
          title: t(Strings.VODS_COLUMN_TITLE_ID),
          minWidth: "10rem",
          enableSort: false,
        });
      }

      const tableConfiguration: TableConf<TableVodData> = {
        columns: columnConfiguration,
        header: {
          className: commonStyles.tableHeader,
          cell: {
            className: commonStyles.tableHeaderCell,
          },
        },
        row: {
          className: commonStyles.tableRow,
          cell: {
            className: commonStyles.tableRowCell,
          },
        },
        content: { className: commonStyles.tableContent },
        valueToShowIfUndefined: { value: "-", className: commonStyles.tableUndefinedValue },
      };

      return tableConfiguration;
    };

    const valueChanged = (value: any, columnKey: string, item: TableVodData) => {
      if (columnKey === "checked") onItemChecked?.(item);
    };

    const customRenderCell = (element: JSX.Element | null, elementRef: TableContentRef, columnKey: string, item: TableVodData) => {
      if (columnKey === "targetName") {
        const renderName = () => {
          let date: string | undefined = undefined;
          if (item.targetStartedAt || item.targetEndedAt) {
            date = `${new Intl.DateTimeFormat(undefined, {
              day: "numeric",
              month: "long",
              year: "numeric"
            }).format(item.targetStartedAt ?? item.targetEndedAt)} - ${
              item.targetStartedAt
                ? new Intl.DateTimeFormat(undefined, {
                    hour: "2-digit",
                    minute: "2-digit",
                    hourCycle: "h23",
                  }).format(item.targetStartedAt)
                : ""
            } ${
              item.targetEndedAt
                ? new Intl.DateTimeFormat(undefined, {
                    hour: "2-digit",
                    minute: "2-digit",
                    hourCycle: "h23",
                  }).format(item.targetEndedAt)
                : ""
            }`;
          }
          const ref = createRef<HTMLDivElement>();
          return (
            <div ref={ref} className={[styles.targetName].join(" ")} style={{ maxWidth: "400px", maxHeight: "100%" }}>
              <div className="text-wrap-ellipsis" style={{ maxWidth: "100%", maxHeight: "100%" }}>
                {item.targetName ?? null}
                {date ? (
                  <span className="text-wrap-ellipsis" style={{ marginLeft: "10px", color: Colors.getMainWhite(0.8), fontSize: "0.8rem" }}>
                    {date}
                  </span>
                ) : null}
                <MessageOnHover targetRef={ref} mode={MessageOnHoverMode.INFO} delay={100} message={t(Strings.VODS_COLUMN_TARGET_MESSAGE_ON_HOVER)} />
              </div>
            </div>
          );
        };
        const style: React.CSSProperties = { maxWidth: "100%", maxHeight: "100%" };
        if (item.targetLink) {
          Object.assign(style, {});
        }
        return (
          <Touchable
            style={style}
            disabled={!item.targetLink}
            onPressOut={() => {
              window.open(item.targetLink, "_blank");
            }}
          >
            {renderName()}
          </Touchable>
        );
      }
      if (columnKey === "state") {
        if (item.error) {
          const elementRef = createRef<HTMLDivElement>();
          return (
            <div ref={elementRef} style={{ color: Colors.getTorchRed() }}>
              {t(Strings.ERROR)}
              <MessageOnHover targetRef={elementRef} mode={MessageOnHoverMode.ERROR} message={t(Strings.ERROR_CONTACT_ADMINISTRATOR)} />
            </div>
          );
        }

        return "";
      }
      if (columnKey === "actions") {
        if (item.error) {
          const elementRef = createRef<HTMLDivElement>();
          return (
            <div
              ref={elementRef}
              style={{ maxWidth: "100%", maxHeight: "100%", display: "flex", flexDirection: "row", alignItems: "cener", justifyContent: "center", gap: "5px" }}
            >
              <Touchable
                style={{ flex: 1, backgroundColor: Colors.getBlueCornflower(), borderRadius: "5px", color: "white", padding: "3px 6px" }}
                onPress={() => onAction?.("recover", item)}
              >
                {t(Strings.VOD_ACTION_RECOVER)}
                <MessageOnHover targetRef={elementRef} mode={MessageOnHoverMode.INFO} message={t(Strings.VOD_ACTION_RECOVER_HOVER_MESSAGE)} />
              </Touchable>
              <Touchable
                style={{ flex: 1, backgroundColor: Colors.getTorchRed(), borderRadius: "5px", color: "white", padding: "3px 6px" }}
                onPress={() => onAction?.("delete", item)}
              >
                {t(Strings.VOD_ACTION_DELETE)}
              </Touchable>
            </div>
          );
        }
        if (
          item.vod.state === VOD_STATE.UPLOAD_ONGOING ||
          item.vod.state === VOD_STATE.PUBLISH_START_WAITING_SERVICE ||
          item.vod.state === VOD_STATE.PUBLISH_ONGOING
        ) {
          return <div style={{ color: Colors.getBlueBayoux() }}>{t(Strings.VOD_UPLOADING)}</div>;
        }
        return (
          <div
            style={{ maxWidth: "100%", maxHeight: "100%", display: "flex", flexDirection: "row", alignItems: "cener", justifyContent: "center", gap: "5px" }}
          >
            <Touchable
              style={{ flex: 1, backgroundColor: Colors.getMountainMeadow(), borderRadius: "5px", color: "white", padding: "3px 6px" }}
              onPress={() => onAction?.("view", item)}
            >
              {t(Strings.VOD_ACTION_PREVIEW)}
            </Touchable>
            <Touchable
              style={{ flex: 1, backgroundColor: Colors.getTorchRed(), borderRadius: "5px", color: "white", padding: "3px 6px" }}
              onPress={() => onAction?.("delete", item)}
            >
              {t(Strings.VOD_ACTION_DELETE)}
            </Touchable>
          </div>
        );
      }
      return element;
    };

    const transformValue = (columnKey: string, item: TableVodData, initialValue: any, data: TableVodData[], index: number) => {
      /*if (columnKey === "checked") {
        return !item.error ? initialValue : false;
      }*/
      if (columnKey === "duration") return item.duration === undefined ? "" : formatTime(item.duration, 1);
      if (columnKey === "size") return item.size === undefined ? "" : formatBytes(item.size, 1);
      if (columnKey === "date")
        return item.date
          ? new Intl.DateTimeFormat(undefined, {
              day: "numeric",
              month: "long",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hourCycle: "h23",
            })
              .format(item.date)
              .replace(",", " -")
          : undefined;
    };

    const addCustomStyleOnCell = (columnKey: string, item: TableVodData) => {
      const result: TableStyle = {};
      /*if (columnKey === "checked") {
        if (item.error) result.style = { opacity: 0.2, pointerEvents: "none" };
      }*/
      return result;
    };

    const addCustomStyleOnRow = (item: TableVodData, currData: TableVodData[], index: number) => {
      const result: TableStyle = {};
      const rowStyle: React.CSSProperties = {};
      result.style = rowStyle;
      result.style = rowStyle;
      return result;
    };

    const onRenderTableStarts = () => {};

    const onRenderTableEnded = () => {};

    const renderTable = () => (
      <Table
        className={commonStyles.table}
        data={data}
        keyExtractor={(_, item) => `key-${item.vodId}`}
        configuration={generateConfiguration()}
        transformValue={transformValue}
        onRenderCellRow={customRenderCell}
        onStyleCellRow={addCustomStyleOnCell}
        onStyleRow={addCustomStyleOnRow}
        onChangeValue={valueChanged}
        onRenderStarts={() => onRenderTableStarts}
        onRenderEnded={onRenderTableEnded}
        onSort={(key, dir) => {
          if (dir && dir !== TableSortDirection.NONE) return onSort?.(key, dir) ?? true;
          return true;
        }}
      />
    );

    const classes = [commonStyles.container];
    if (className) classes.push(className);
    return (
      <div className={classes.join(" ")} style={style} ref={containerRef}>
        {renderTable()}
      </div>
    );
  }
);

export default TableVods;
