import { KastWebSdkClient } from "@kalyzee/kast-web-sdk";
import { CompatibilityInfo as CompatibilityInfoApiRest } from "@kalyzee/kast-web-sdk/http";
import { CompatibilityInfo as CompatibilityInfoWebsocketServer } from "@kalyzee/kast-web-sdk/websocket";
import { getHttpApiBaseUrl, getUserSocketUrl } from "./request";

export const sdkClient = new KastWebSdkClient({
  http: {
    baseUrl: getHttpApiBaseUrl(),
  },
  websocket: {
    baseUrl: getUserSocketUrl(),
    retryAmount: 5,
    retryDelay: 5000,
  },
});

export interface CompatibilityInfos {
  apiRest: CompatibilityInfoApiRest;
  websocketServer: CompatibilityInfoWebsocketServer;
}
export const getCompatibilityInfos = async (): Promise<Partial<CompatibilityInfos>> => {
  const result: Partial<CompatibilityInfos> = {};
  const apiClient = sdkClient.getHttpClient();
  const websocketClient = sdkClient.getWebsocketClient();
  const apiRestCompatInfo = await apiClient?.getCompatibilityInfo();
  if (apiRestCompatInfo) {
    result.apiRest = apiRestCompatInfo;
    const websocketCompatInfo = await websocketClient?.getCompatibilityInfo(apiRestCompatInfo.apiVersion, "version");
    if (websocketCompatInfo) {
      result.websocketServer = websocketCompatInfo;
    }
  }
  return result;
};
