const DEV_ENV_PREFIX = "REACT_APP_";

// Store environments variables
const ENVS = (window as any).__environment_variables__ ?? {};
// Clean environments variables
(window as any).__environment_variables__ = {};

const getUrl = (str: string | undefined): string | undefined => {
  if (!str) return undefined;
  let url;
  try {
    const urlObj = new URL(str);
    url = urlObj.href;
  } catch {}
  return url;
};

const getEnv = (key: string): string | undefined => {
  let result: string | undefined;
  if (process.env.NODE_ENV === "development") {
    result = process.env[`${DEV_ENV_PREFIX}${key}`];
    if (result !== undefined) {
      return result;
    }
  }
  return ENVS[key];
};

export const getEnvApiUrl = (): string | undefined => {
  return getUrl(getEnv("API_URL"));
};

export const getEnvApiUrls = (): { [hostname: string]: string | undefined } => {
  const str = getEnv("API_URLS");
  if (!str) return {};
  let json: { [hostname: string]: string | undefined } = {};
  try {
    const parsed = JSON.parse(str);
    Object.keys(parsed).forEach((k) => {
      const value = parsed[k];
      if (typeof value === "string") {
        json[k] = getUrl(value);
      }
    });
  } catch {}
  return json;
};

export const getEnvApiMode = (): string | undefined => {
  return getEnv("API_MODE");
};

export const getEnvOAuth2ClientId = (): string | undefined => {
  return getEnv("OAUTH2_CLIENT_ID");
};

export const getEnvBuildDate = (): Date | undefined => {
  const str = getEnv("BUILD_DATE");
  if (!str) return undefined;
  return new Date(str);
};
