import React, { ForwardedRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useOuterClickRef } from '../../hooks/click';
import { CustomCheckBox, CustomCheckBoxRef } from '../form/CustomCheckBox';
import Touchable from './Touchable';
import { useAppTranslation } from '../../hooks/translation';
import { Strings } from '../../constants/translation';
import { toBase64 } from '../../helpers/utils';

import { ReactComponent as IconWarning } from '../../../assets/icons/warning.svg';
import { ReactComponent as IconLoading } from '../../../assets/icons/loading.svg';
import { ReactComponent as IconError } from '../../../assets/icons/error.svg';
import { ReactComponent as IconSuccess } from '../../../assets/icons/success.svg';
import { ReactComponent as IconInfo } from '../../../assets/icons/info.svg';

import styles from './Popup.module.css';
import Colors from '../../constants/colors';

const DEFAULT_ENABLE_BACKDROP_DISMISS = true;
const DEFAULT_ENABLE_NOT_SHOW_AGAIN = true;
const DEFAULT_ENABLE_CLOSE_BUTTON = true;

export enum PopupId {
  UPDATE_INSTALL_CONFIRMATION = 'update_install_confirmation',
  UPDATE_SCHEDULE_SILENT = 'update_schedule_silent',
  UPDATE_TIMEZONE = 'update_timezone',
  WARNING_TIME_SYNCHRO = 'warning_time_synchro',
  SYSTEM_WARNING_REBOOT = 'system_warning_reboot',
  SYSTEM_WARNING_FACTORY_RESET = 'system_warning_factory_reset',
  VOD_DELETE = "vod_delete",
  VOD_PREVIEW = "vod_preview"
}

export enum PopupButtonType {
  OK = 'ok',
  CANCEL = 'cancel',
  VALIDATE = 'validate',
}

export enum PopupIconType {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  LOADING = 'loading',
}

export interface PopupOnCloseData {
  popupId: PopupId;
  notShowAgain: boolean;
  closeFromButton: PopupButtonElement | null;
  closeFromButtonIndex: number;
  closeFromClickOutside: boolean;
  closeFromCloseButton: boolean;
}

export type PopupButtonElement = {
  element: React.ReactNode,
  onClick?: () => boolean | Promise<boolean>,
  type: PopupButtonType,
  disabled?: boolean,
  className?: string,
  style?: React.CSSProperties,
};
export interface PopupProps {
  id: PopupId; // allow to identify popup
  content: React.ReactNode;
  iconContent?: PopupIconType,
  title?: React.ReactNode;
  iconTitle?: PopupIconType,
  buttons?: PopupButtonElement[];
  enableCloseButton?: boolean;
  enableBackdropDismiss?: boolean;
  enableNotShowAgain?: boolean;
  onClose?: (data: PopupOnCloseData) => void;
  className?: string;
  style?: React.CSSProperties;
}

export interface PopupRef {
  updateTitle: (ttile: string) => void;
  updateContent: (content: React.ReactNode) => void;
  updateButtons: (buttons: PopupButtonElement[]) => void;
  updateIconTitle: (icon : PopupIconType) => void;
  updateIconContent: (icon : PopupIconType) => void;
}

// usefull for 'notShowAgain'
const VALIDATION_TYPES = [PopupButtonType.OK, PopupButtonType.VALIDATE];

const Popup = React.forwardRef(({
  id,
  content,
  iconContent,
  title,
  iconTitle,
  buttons,
  enableCloseButton,
  enableBackdropDismiss,
  enableNotShowAgain,
  onClose,
  className,
  style,
}: PopupProps, forwardRef: ForwardedRef<PopupRef | undefined>) => {
  const { t } = useAppTranslation();
  const [titleState, setTitleState] = useState<React.ReactNode>(title);
  const [contentState, setContentState] = useState<React.ReactNode>(content);
  const [buttonsState, setButtonsState] = useState<PopupButtonElement[]>(buttons ?? []);
  const [iconTitleState, setIconTitleState] = useState<PopupIconType | undefined>(iconTitle);
  const [iconContentState, setIconContentState] = useState<PopupIconType | undefined>(iconContent);

  const checkboxRef = useRef<CustomCheckBoxRef>(null);
  const containerRef = useOuterClickRef<HTMLDivElement>(() => {
    if (enableBackdropDismiss) {
      const closeData: PopupOnCloseData = {
        popupId: id,
        notShowAgain: checkboxRef?.current?.isChecked() ?? false,
        closeFromButton: null,
        closeFromButtonIndex: -1,
        closeFromClickOutside: true,
        closeFromCloseButton: false,
      };
      onClose?.(closeData);
    }
  });
  useImperativeHandle(forwardRef, () => ({
    updateTitle: (value: string) => setTitleState(value),
    updateContent: (node : React.ReactNode) => setContentState(node),
    updateButtons: (b: PopupButtonElement[]) => setButtonsState(b),
    updateIconTitle: (icon : PopupIconType) => setIconTitleState(icon),
    updateIconContent: (icon : PopupIconType) => setIconContentState(icon),
  }));

  useEffect(() => { setTitleState(title); }, [title]);
  useEffect(() => { setContentState(content); }, [content]);
  useEffect(() => { setButtonsState(buttons ?? []); }, [buttons]);
  useEffect(() => { setIconTitleState(iconTitle); }, [iconTitle]);
  useEffect(() => { setIconContentState(iconContent); }, [iconContent]);

  const renderIcon = (iconType: PopupIconType | undefined) => {
    if (!iconType) return null;
    const width = 30;
    if (iconType === PopupIconType.SUCCESS) {
      return <IconSuccess width={width} fill={Colors.getMountainMeadow()} />;
    }
    if (iconType === PopupIconType.INFO) {
      return <IconInfo width={width} fill={Colors.getBlueCornflower()} />;
    }
    if (iconType === PopupIconType.WARNING) {
      return <IconWarning width={width} fill={Colors.getBroom()} />;
    }
    if (iconType === PopupIconType.ERROR) {
      return <IconError width={width} fill={Colors.getTorchRed()} />;
    }
    if (iconType === PopupIconType.LOADING) {
      return <IconLoading width={width} />;
    }
    return null;
  };

  const renderHeader = () => {
    if (!title) return null;
    return (
      <div className={styles.header}>
        {renderIcon(iconTitleState)}
        <div className={styles.headerContent}>
          {titleState}
          {
            enableCloseButton ? (
              <Touchable
                className={styles.closeButton}
                onPressOut={() => {
                  const closeData: PopupOnCloseData = {
                    popupId: id,
                    notShowAgain: checkboxRef?.current?.isChecked() ?? false,
                    closeFromButton: null,
                    closeFromButtonIndex: -1,
                    closeFromClickOutside: false,
                    closeFromCloseButton: true,
                  };
                  onClose?.(closeData);
                }}
              >
                {t(Strings.POPUP_CLOSE)}
              </Touchable>
            ) : null
          }
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const renderNotShowAgain = () => (
      <div className={styles.notShowAgainContainer}>
        <CustomCheckBox ref={checkboxRef} />
        <div className={styles.notShowAgainText}>
          {t(Strings.POPUP_NOT_SHOW_AGAIN)}
        </div>
      </div>
    );
    return (
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          {renderIcon(iconContentState)}
          <div className={styles.contentText}>
            {contentState}
          </div>
        </div>
        {enableNotShowAgain ? renderNotShowAgain() : null}
      </div>
    );
  };

  const renderButton = (button: PopupButtonElement, index: number) => {
    const buttonClass = [styles.buttonContainer];
    if (button.type) buttonClass.push(styles[`buttonType_${button.type}`]);
    if (button.className) buttonClass.push(button.className);
    if (button.disabled) buttonClass.push(styles.buttonDisabled);
    const key = toBase64(JSON.stringify(button));
    return (
      <Touchable
        className={buttonClass.join(' ')}
        style={button.style}
        key={key}
        onPressOut={async () => {
          if (button.onClick) {
            const enableAction = await button.onClick?.();
            if (!enableAction) return;
          }
          let notShowAgain = false;
          if (checkboxRef?.current?.isChecked() && VALIDATION_TYPES.includes(button.type)) {
            notShowAgain = true;
          }
          const closeData: PopupOnCloseData = {
            popupId: id,
            notShowAgain,
            closeFromButton: button,
            closeFromButtonIndex: index,
            closeFromClickOutside: false,
            closeFromCloseButton: false,
          };
          onClose?.(closeData);
        }}
        disabled={button.disabled}
      >
        {button.element}
      </Touchable>
    );
  };

  const checkButtons = (buttonElements: PopupButtonElement[]) => {
    const button = buttonElements.find((b) => (b.type ? VALIDATION_TYPES.includes(b.type) : false));
    if (!button) throw new Error(`You need at least one button with type in : [${VALIDATION_TYPES}]`);
  };

  const renderButtons = () => {
    const buttonElements: PopupButtonElement[] = [];
    if (buttonsState) {
      buttonsState.forEach((button) => {
        buttonElements.push({
          ...button,
          element: button.element,
        });
      });
    } else {
      buttonElements.push({
        element: t(Strings.OK),
        type: PopupButtonType.OK,
      });
    }

    checkButtons(buttonElements);
    return (
      <div className={styles.buttonsContainer}>
        {buttonElements.map((el, i) => renderButton(el, i))}
      </div>
    );
  };

  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
      ref={containerRef}
    >
      {renderHeader()}
      {renderContent()}
      {renderButtons()}
    </div>
  );
});

Popup.defaultProps = {
  iconContent: undefined,
  title: undefined,
  iconTitle: undefined,
  buttons: undefined,
  enableCloseButton: DEFAULT_ENABLE_CLOSE_BUTTON,
  enableBackdropDismiss: DEFAULT_ENABLE_BACKDROP_DISMISS,
  enableNotShowAgain: DEFAULT_ENABLE_NOT_SHOW_AGAIN,
  onClose: undefined,
  className: undefined,
  style: undefined,
};

export default Popup;
